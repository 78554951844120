import { firestore } from "@/firebase"
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"

export abstract class FirestoreClass {
    protected _id: string

    constructor(id: string) {
        this._id = id
    }

    async save() {
        if (this._id) {
            await updateDoc(this.ref, this.toData())
        } else {
            const addedDoc = await addDoc(this.collectionRef, this.toData())
            this._id = addedDoc.id
        }
    }
    async set(merge: boolean = true) {
        if (this._id) {
            await setDoc(this.ref, this.toData(), { merge: merge })
        } else {
            const addedDoc = await addDoc(this.collectionRef, this.toData())
            this._id = addedDoc.id
        }
    }
    async delete() {
        if (this._id) {
            await deleteDoc(this.ref)
        }
    }
    async load() {
        const doc = await getDoc(this.ref)
        if (!doc.exists()) {
            return false
        }
        this.fromData(doc.data()!)
        return true
    }
    fromData(data: Record<string, unknown>): void {
        throw new Error("not_implemented")
        console.log(data)
    }
    toData() {
        throw new Error("not_implemented")
        return {}
    }

    get id() {
        return this._id
    }
    get ref() {
        throw new Error("not_implemented")
        return doc(collection(firestore, ""))
    }
    get collectionRef() {
        throw new Error("not_implemented")
        return collection(firestore, "")
    }
}