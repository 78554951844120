import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import './assets/styles.css';
import './assets/mystyles.css'

import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

const DEFAULT_TITLE = 'Kundenportal';

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title + " - " + DEFAULT_TITLE || DEFAULT_TITLE;
  });
});
Vue.component("loading", Loading);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
