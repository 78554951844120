
import { auth } from "@/firebase";
import imageFunctionsMixin from "@/mixins/imageFunctions";
import store from '@/store';// path to store file

import router from "@/router";

export default {
  name: "Header",
  mixins: [imageFunctionsMixin],
  data() {
    return {};
  },
  methods: {
    getLogo():any {     
      if (store.state.company && store.state.company.logo) {
        return store.state.company.logo
      }
      return require("@/assets/images/logo-business-luchs.svg")
    },
    async logout() {
      await auth.signOut()
      router.push("Login")
    },
    gotoByName(routeName: string) {
      router.push({ name: routeName });
    }
  },
  computed: {
    classes() {
      if (navigator.userAgent == 'kundenportal_florianthoms_app') {
        return "appHeader";
      }
      return "";
    }
  },
  mounted() {
    //console.log(this.$store.state.auth.isSignedIn);
  },
};
