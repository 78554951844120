export default {
    data() {
        return {


        }
    },
    created: function () {

    },
    methods: {
        getAvatarOrDummy(employee:any){
            
            let img = employee?.picture
            if(img){
                
                return img;
            }else{
                return `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0IDI0QzI1LjE5MzUgMjQgMjYuMzM4MSAyMy41MjU5IDI3LjE4MiAyMi42ODJDMjguMDI1OSAyMS44MzgxIDI4LjUgMjAuNjkzNSAyOC41IDE5LjVDMjguNSAxOC4zMDY1IDI4LjAyNTkgMTcuMTYxOSAyNy4xODIgMTYuMzE4QzI2LjMzODEgMTUuNDc0MSAyNS4xOTM1IDE1IDI0IDE1QzIyLjgwNjUgMTUgMjEuNjYxOSAxNS40NzQxIDIwLjgxOCAxNi4zMThDMTkuOTc0MSAxNy4xNjE5IDE5LjUgMTguMzA2NSAxOS41IDE5LjVDMTkuNSAyMC42OTM1IDE5Ljk3NDEgMjEuODM4MSAyMC44MTggMjIuNjgyQzIxLjY2MTkgMjMuNTI1OSAyMi44MDY1IDI0IDI0IDI0VjI0Wk0yNyAxOS41QzI3IDIwLjI5NTYgMjYuNjgzOSAyMS4wNTg3IDI2LjEyMTMgMjEuNjIxM0MyNS41NTg3IDIyLjE4MzkgMjQuNzk1NiAyMi41IDI0IDIyLjVDMjMuMjA0NCAyMi41IDIyLjQ0MTMgMjIuMTgzOSAyMS44Nzg3IDIxLjYyMTNDMjEuMzE2MSAyMS4wNTg3IDIxIDIwLjI5NTYgMjEgMTkuNUMyMSAxOC43MDQ0IDIxLjMxNjEgMTcuOTQxMyAyMS44Nzg3IDE3LjM3ODdDMjIuNDQxMyAxNi44MTYxIDIzLjIwNDQgMTYuNSAyNCAxNi41QzI0Ljc5NTYgMTYuNSAyNS41NTg3IDE2LjgxNjEgMjYuMTIxMyAxNy4zNzg3QzI2LjY4MzkgMTcuOTQxMyAyNyAxOC43MDQ0IDI3IDE5LjVWMTkuNVpNMzMgMzEuNUMzMyAzMyAzMS41IDMzIDMxLjUgMzNIMTYuNUMxNi41IDMzIDE1IDMzIDE1IDMxLjVDMTUgMzAgMTYuNSAyNS41IDI0IDI1LjVDMzEuNSAyNS41IDMzIDMwIDMzIDMxLjVaTTMxLjUgMzEuNDk0QzMxLjQ5ODUgMzEuMTI1IDMxLjI2OSAzMC4wMTUgMzAuMjUyIDI4Ljk5OEMyOS4yNzQgMjguMDIgMjcuNDMzNSAyNyAyNCAyN0MyMC41NjUgMjcgMTguNzI2IDI4LjAyIDE3Ljc0OCAyOC45OThDMTYuNzMxIDMwLjAxNSAxNi41MDMgMzEuMTI1IDE2LjUgMzEuNDk0SDMxLjVaIiBmaWxsPSIjMkU0MzVFIi8+Cjwvc3ZnPgo=`;
            }
            
        }

    }
};