<template>
  <v-app>
    <v-main>
      <v-snackbar id="application-snackbar" v-model="$store.state.snackbar.visible" :timeout="-1"
        :color="$store.state.snackbar.color">
        <center>{{ $store.state.snackbar.text }}</center>
      </v-snackbar>
      <div v-if="!authDone">Lädt...</div>
      <div v-else>
        <div class="h-screen flex flex-col">
          <KPHeader v-if="$route.path != '/login'" class="flex-none" />
          <router-view class="flex-1" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import store from './store';
import KPHeader from "@/components/Header.vue";

export default {
  name: "App",
  mixins: [],
  components: {
    KPHeader
  },
  data: () => ({

  }),
  methods: {
    getColor() {
      if (this.$store.state.company) {
        return {
          primary: `rgb(${this.$store.state.company.colors.primary.r}, ${this.$store.state.company.colors.primary.g}, ${this.$store.state.company.colors.primary.b})`,
          border: `rgb(${this.$store.state.company.colors.border.r}, ${this.$store.state.company.colors.border.g}, ${this.$store.state.company.colors.border.b})`,
          card: `rgb(${this.$store.state.company.colors.card.r}, ${this.$store.state.company.colors.card.g}, ${this.$store.state.company.colors.card.b})`,
        }
      } else {
        return {
          primary: `f4f9fe`,
          border: `rgb(46, 67, 94)`,
          card: `78AEF5`,
        }
      }
    }
  },
  computed: {
    authDone() {
      const authDone = this.$store.state.auth.authDone
      return authDone
    },
  },
  async mounted() { },
}

</script>

<style>
#app {
  --color-primary: v-bind("getColor().primary") !important;
  --color-border: v-bind("getColor().border") !important;
  --color-card: v-bind("getColor().card") !important;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./assets/fonts/Manrope/static/Manrope-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./assets/fonts/Manrope/static/Manrope-Medium.ttf) format("truetype");
  font-weight: medium;
  font-style: normal;
}
</style>
