import { Log } from '@/classes/Log'
import { firestore } from '@/firebase'
import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      isSignedIn: false,
      authDone: false
    },
    snackbar: {
      timeout: 5000,
      color: "green",
      text: "Standard",
      visible: false,
      handler: null as unknown as NodeJS.Timeout
    },
    profile: {} as any,
    customerprofile: null as any,
    customerData: null as any,
    customerPortalData: null as any,
    customerPortalDataRef: null as any,
    selfDisclosureNewData: null as any,
    selfDisclosureNewRef: null as any,
    selfDisclosureRef: null as any,
    company: null as any,
    employee: null as any
  },
  getters: {
  },
  mutations: {
    setAuthClaims(state, { user, claims }) {
      if (claims) {
        state.profile = {
          sub: user.uid,
          firstName: claims.firstName,
          lastName: claims.lastName,
          email: claims.auth_email,
          gender: claims.gender,
          phone: claims.phone,
          picture: claims.picture,
          company: claims.company,
          admin: claims.admin == true
        }
      } else {
        state.profile = null
      }
    }
  },
  actions: {
    showSnackbar({ commit }, payload) {
      this.state.snackbar.text = payload.text;
      this.state.snackbar.color = payload.color;

      clearTimeout(this.state.snackbar.handler);

      this.state.snackbar.visible = true;
      this.state.snackbar.handler = setTimeout(() => {
        this.state.snackbar.visible = false;
      }, this.state.snackbar.timeout);
    },
    async attachCompanyAndCustomer({ commit }, payload) {
      const data = []

      const customersRef = query(collectionGroup(firestore, 'customer'), where('userid', '==', this.state.profile.sub));
      const customersSnapshot = await getDocs(customersRef);

      for (const customerDoc of customersSnapshot.docs) {
        const customerData = customerDoc.data()
        const customerRef = customerDoc.ref

        if (customerData?.customerPortalActive !== true) {
          continue
        }

        if (customerData) {
          customerData.id = customerDoc.id
        }


        const companyRef = customerRef.parent.parent
        if (companyRef) {
          if (companyRef.parent.id == "company") {
            const company = await getDoc(doc(collection(firestore, "public_company"), companyRef.id))
            const companyData = company.data() as any
            companyData.id = company.id

            
            const customerPortalDataRef = doc(firestore, customerRef.path + "/meta/customerPortalData")
            const selfDisclosureNewRef = doc(firestore, customerRef.path + "/meta/selfDisclosureNew")
            const selfDisclosureRef = doc(firestore, customerRef.path + "/meta/selfDisclosure")
            
            const customerPortalDataSnapshot = await getDoc(customerPortalDataRef)
            const customerPortalData = customerPortalDataSnapshot.data()

            data.push({
              company: companyData,
              customer: customerData,
              customerPortalData: customerPortalData,
              customerPortalDataRef: customerPortalDataRef,
              selfDisclosureNewRef: selfDisclosureNewRef,
              selfDisclosureRef: selfDisclosureRef
            })

          }
        }

      }

      
      this.state.company = data[0].company
      this.state.customerData = data[0].customer
      this.state.customerPortalDataRef = data[0].customerPortalDataRef
      this.state.customerPortalData = data[0].customerPortalData
      this.state.selfDisclosureNewRef = data[0].selfDisclosureNewRef
      this.state.selfDisclosureRef = data[0].selfDisclosureRef

      // const selfDisclosureNew = await getDoc(doc(collection(firestore, "public_company"), companyRef.id))


      if (!this.state.customerPortalData) {
        // go
      }

      // console.log(this.$store.state.customerPortalData);


      

      const employeeRef = doc(collection(firestore, `/company/${data[0].company.id}/publicEmployee`), this.state.customerData.owner)
      const employeeSnapshot = await getDoc(employeeRef)
      const employee = employeeSnapshot.data()
      this.state.employee = employee
    },
    async makeLogEntry(state, { docRefPath, frontend, newData, oldData, logType }) {
      try{
          const log = new Log()
          log.username = `${this.state.profile?.firstName} ${this.state.profile?.lastName}` ?? "unbekannt"
          log.userid = this.state.profile?.sub ?? "unbekannt"
          log.companyid = this.state.company?.id ?? "unbekannt"
          log.docRefPath = docRefPath
          log.frontend = frontend
          log.newData = newData
          log.oldData = oldData
          log.logType = logType
          
          await log.save()
      }
      catch(err) {
          console.log(err)
      }
  }
  },
  modules: {
  }
})
